import {
    BACK_OFFICE,
    BUDGET,
    DECISION,
    HUMAN_RESOURCES,
    ModuleT,
} from 'business/constants';

export type ModuleDetailsT = { label: string; available: boolean };

export const MODULES: Record<ModuleT, ModuleDetailsT> = {
    [DECISION]: { label: 'modules.decision', available: true },
    [BUDGET]: { label: 'modules.budget', available: true },
    [BACK_OFFICE]: { label: 'modules.backOffice', available: true },
    [HUMAN_RESOURCES]: {
        label: 'modules.humanResources',
        available: true,
    },
};

export const CSV_IMPORT = 'csv_import';
const UNIFIED_ORG_CHARTS = 'unified_org_charts';
export const ESSENTIAL = 'decision_essential';
export const FINANCE_SOFTWARE_SWITCH = 'finance_software_switch';

export type ExtraFeatureT =
    | typeof CSV_IMPORT
    | typeof UNIFIED_ORG_CHARTS
    | typeof ESSENTIAL
    | typeof FINANCE_SOFTWARE_SWITCH;

export const EXTRA_FEATURES: Record<
    ModuleT,
    Partial<Record<ExtraFeatureT, ModuleDetailsT>>
> = {
    [DECISION]: {
        [ESSENTIAL]: {
            label: 'extraFeatures.essential',
            available: true,
        },
        [CSV_IMPORT]: {
            label: 'extraFeatures.csvImport',
            available: true,
        },
        [UNIFIED_ORG_CHARTS]: {
            label: 'extraFeatures.unifiedOrgCharts',
            available: true,
        },
    },
    [BUDGET]: {},
    [HUMAN_RESOURCES]: {},
    [BACK_OFFICE]: {},
};

export const VIEW = 'VIEW';
export const CITIZEN_RELATION = 'citizen_relation';
export const EXTRACTION_LOGS = 'extraction_logs';
export const FINANCE = 'finance';
export const HOUSING_POPULATION = 'housing_population';
export const HR = 'hr';
export const MAD = 'mad';
export const MANTY_BUDGET = 'manty_budget';
export const MISC = 'misc';
export const POPULATION = 'population';
export const PROJECT_MANAGEMENT = 'project_management';
export const TECHNICAL_SERVICES = 'technical_services';
export const URBAN_PLANNING = 'urban_planning';
export const CSV = 'CSV';

export const DECISION_PERIMETER = [
    VIEW,
    CITIZEN_RELATION,
    EXTRACTION_LOGS,
    FINANCE,
    HOUSING_POPULATION,
    HR,
    MAD,
    MANTY_BUDGET,
    MISC,
    POPULATION,
    PROJECT_MANAGEMENT,
    TECHNICAL_SERVICES,
    URBAN_PLANNING,
    CSV,
];

export type DecisionPerimeterT =
    | typeof VIEW
    | typeof CITIZEN_RELATION
    | typeof EXTRACTION_LOGS
    | typeof FINANCE
    | typeof HOUSING_POPULATION
    | typeof HR
    | typeof MAD
    | typeof MANTY_BUDGET
    | typeof MISC
    | typeof POPULATION
    | typeof PROJECT_MANAGEMENT
    | typeof TECHNICAL_SERVICES
    | typeof URBAN_PLANNING
    | typeof CSV;
