import { ADMIN, ADMIN_BUDGET_ROLES, BUDGET_USER } from 'business/constants';
import { UserRole } from 'business/definitions';
import { useSelector } from 'store/hooks';

export const useUserHasRole = (role: UserRole) =>
    useSelector((state) => state.vision.user.roles.includes(role));

export const useIsSupportUser = () =>
    useSelector((state) => state.vision.isSupportUser);

export const useIsAdminAndNotSupportUser = () => {
    const isAdmin = useUserHasRole(ADMIN);
    return isAdmin && !useIsSupportUser();
};

export const useHasDafAccess = () =>
    useSelector((state) => state.vision.hasAccessDaf);

export const useIsAdminCategories = () =>
    useSelector((state) => state.vision.isAdminCategories);

export const useIsSuperFinancesWriter = () =>
    useSelector((state) => state.vision.isSuperFinancesWriter);

export const useHasAccessCadrageGestion = () =>
    useSelector((state) => state.vision.hasAccessCadrageGestion);

export const useHasAccessBudgetsGestion = () =>
    useSelector((state) => state.vision.hasAccessBudgetsGestion);

export const useIsBudgetUser = () =>
    useSelector((state) => state.vision.user.roles.includes(BUDGET_USER));

export const isAdminBudget = (roles: UserRole[]) =>
    roles.some((role) => ADMIN_BUDGET_ROLES.includes(role));
